/* Globals */

a{
    text-decoration: none;
    color: unset;
}

/* Globals End */

.sidebar-top{
    width: 20%;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}
.sidebar{
    height: 85%;
    width: 85%;
    background: #0a0a0a;
    opacity: 0.6;
    border-radius: 25px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.sidebar-links{
    width: 85%;
    letter-spacing: 2px;
    font-size: 20px;
    text-align: center;
}
.nav-active a{
    color: #0046f0 !important;
    font-weight: bold;
    border: 1px solid white;
    padding: 2% 10%;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 4px 0 black;
}

/* Mobile */

@media only screen and (max-width: 767px) {

  #nav-icon1, #nav-icon2 {
    width: 40px;
    height: auto;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
		margin: 8px 10px 5px auto;
  }
  #nav-icon1 span, #nav-icon2 span{
    display: flex;
		flex-direction: row;
		margin-bottom: 6px;
    height: 4px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
	#nav-icon1.open span{
		position: absolute;
	}
  #nav-icon1.open span:nth-child(1) {
    top: 50%;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  #nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  #nav-icon1.open span:nth-child(3) {
    top: 50%;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
	.hidden-ham{
		opacity: 0;
	}
	.menu-but{
		display: flex;
		flex-direction: row-reverse;
		background: #0a0a0a;
    opacity: 0.8;
		border-radius: 15px;
	}
  .sidebar-mobile-top{
    all: unset;  
    position: absolute; 
    width: 100%;
    height: auto;
    padding: 1%;
		flex-direction: column;
		box-sizing: border-box;
		margin-bottom: 3%;
  }
  .sidebar-mobile{
    width: 100%;
    padding-top: 7%;
		margin-top: 2%;
		opacity: .8;
		max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
  }
	.sidebar-links{
		margin-bottom: 7%;
	}
	.hide-navbar{
		max-height: 0;
		margin: 0;
		padding: 0;
		opacity: 0;
		transition: 0.25s ease-in;
	}
	.show-navbar{
		max-height: 500px;
    transition: max-height 0.25s ease-in;
	}
}

/* keyframes */

/* @keyframes openNav{
	0%{
		opacity: 0;
		height: 0;
	}
	100%{
		opacity: .6;
		height: ;
	}
} */
