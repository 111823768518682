/* Common */

.d-none{
  display: none;
}

@media only screen and (max-width: 767px) {
  .d-sm-none{
    display: none;
  }
}

/* End Common */

.App {
  text-align: center;
  float: left;
  width: 80%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
*{
  letter-spacing: 1px;
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Loader */

.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


/* Loder end */


.box-top{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  height: 85%;
  max-height: 85%;
  padding: 2%;
  background: rgb(224 224 224 / 50%);
  box-sizing: border-box;
  width: 95%;
  border-radius: 10px;
  overflow: auto;
}
.year-top{
  display: flex;
  justify-content: center;
}
.year-box{
  display: flex;
  width: 20%;
  justify-content: space-around;
}
.year-key{
  padding: 2% 5%;
  border-right: 1px solid #cbcbcb;
  transition: .5s;
  font-weight: bold;
}
.year-key:hover{
  color: rgb(10, 117, 189);
}

/* Mobile */

@media only screen and (max-width: 767px) {
  .App{
    width: 100%;
  }
}
