.login-box{
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d5d5d5;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
  border-top: 3px solid rgb(2, 153, 223);
  justify-content: space-between;
}
.App-Login{
  width: 100%;
  height: 100vh;
  background-color: #f4f4f44d;
}
.login-top{
  height: 100vh;
  display: flex;
  padding-top: 5%;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}
.login-inp{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2em;
  width: 85%;
}
.login-inp input{
  width: 100%;
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #d5d5d5;
  background: #f4f4f44d;
  box-sizing: border-box;
}
.login-inp label{
  margin-bottom: .8em;
  font-size: 14px;
}
.login-inp input:focus-visible{
  outline: none;
  border: 1px solid rgb(2, 153, 223);
  transition: cubic-bezier(0.075, 0.82, 0.165, .5);
}
.buttons-login{
  width: 100%;
  height: 45px;
}
.login-but{
  width: 100%;
  height: 100%;
  border: none;
  background-color: rgb(2, 153, 223);
  border-radius: 0 0 5px 5px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1),.5s;
}
.login-but:hover{
  background-color: rgb(0, 124, 182);
}
.login-logo img{
  width: 6em;
  margin-bottom: 2em;
}
form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-loader{
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  backdrop-filter: brightness(0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-loader span{
  width: 50px;
  height: 50px;
  color: white;
  background: white;
  border-radius: 50%;
  animation: loader-pulse 1.5s linear infinite;
}

@keyframes loader-pulse{
  0%{
    width: 20px;
    height: 20px;
  }
  50%{
    width: 50px;
    height: 50px;
  }
  100%{
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .login-box{
    width: 80vw;
  }
}