.member-item{
    display: flex;
}
.member-det{
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    display: grid;
    grid-template-columns: .5fr 1.5fr 1.5fr 1fr .5fr;
}
.row-heading{
    font-weight: bold;
    font-size: 16px;
    text-align: start;
    /* width: 20%; */
}
.addNew-top{
    display: flex;
    flex-wrap: wrap;
}
.addNew-qstn{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
    white-space: pre;
}
.addNew-qstn label{
    margin-bottom: 10px;
    font-weight: bold;
}
.addNew-qstn input,.addNew-qstn textarea{
    width: 80%;
    height: 25px;
    border: none;
    background: #efefef;
    border-bottom: 2px solid #d1d1d1;
    transition: .5s;
}
.addNew-qstn textarea{
    white-space: pre;
    height: 150px !important;
}
.addNew-qstn img{
    width: 100%;
}
.addNew-qstn select{
    width: 80%;
    height: 30px;
    border: none;
    background: #efefef;
    border-bottom: 2px solid #d1d1d1;
    transition: .5s;
}
.addNew-qstn input:hover,.addNew-qstn textarea:hover{
    border-color: rgb(173, 173, 173);
}
.addNew-qstn option{
    background: white;
}
.addNew-qstn select:focus-visible,.addNew-qstn input:focus-visible{
    outline: none;
    border-color: rgba(2, 129, 248, 0.808);
}
.sub-button{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.sub-button input{
    margin-right: 10%;
    width: 9em;
    height: 2.5em;
    border: none;
    background: #00a1ff;
    color: white;
    border-radius: 2px;
    font-weight: bold;
}
.sub-button button{
    margin-right: 2%;
    width: 6em;
    border: none;
    background: #4d4d4d;
    color: white;
    font-weight: bold;
    border-radius: 2px;
}
.addNew{
    display: flex;
    justify-content: flex-end;
}
.addNew button{
    margin-right: 2%;
    margin-bottom: 2%;
    width: 9em;
    height: 2.5em;
    border: none;
    background: #00a1ff;
    color: white;
    border-radius: 2px;
    font-weight: bold;
}
.lister-item-box{
    /* width: 20%; */
    text-align: start;
}
.lister-item-box img{   
    width: 25px;
    height: 25px;
}
.actions-icons img{
    cursor: pointer;
}

/* Mobile */

@media only screen and (max-width: 767px) {
    .member-det{
        grid-template-columns: .5fr 1.8fr .7fr;
    }
    .addNew-top{
        flex-direction: column;
        padding: 10px;
    }
    .addNew-qstn,.addNew-qstn input, .addNew-qstn textarea{
        width: 100%;
    }
}



